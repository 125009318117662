import axios from "axios";
import React, { useState } from "react";
import mailchimpErrors from "../data/mailchimpErrors";
import "./NewsletterSubscribe.css";

function NewsletterSubscribe() {
  const [email, setEmail] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setSubscriptionStatus("none");
      return;
    }
    setSubscriptionStatus("");
    setErrorMessage("");
    setLoading(true);

    try {
      const postData = {
        email: email,
      };

      const config = {
        headers: {
          "Content-Type": "application/json", // This line specifies the content type as JSON
        },
      };

      const response = await axios.post(
        "https://api.publiwrite.com/newsletter/subscribe",
        JSON.stringify(postData),
        config
      ); // const response2 = await axios.get(`https://api.publiwrite.com/graphql/playground`);

      if (response.data.statusCode === 200) {
        setSubscriptionStatus("success");
        setLoading(false);
      } else {
        setSubscriptionStatus("failed");
        const customErrorMessage = getCustomErrorMessage(response.data.title);
        setErrorMessage(customErrorMessage);
        setLoading(false);
      }
    } catch (error) {
      console.error("Subscription failed:", error);
      setSubscriptionStatus("failed");
      setLoading(false);
    }
  };

  return (
    <>
      <form>
        <div className="input-wrapper">
          <div className="input-line">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              required
              className="inputContainer"
            ></input>
            <button type="submit" onClick={handleSubmit}>
              Notify me
            </button>
          </div>
        </div>

        {subscriptionStatus === "none" && !loading && (
          <div className="newsletterUnderText">
            Please enter your email address
          </div>
        )}
        {subscriptionStatus === "success" && (
          <div className="newsletterUnderText">
            Successfully subscribed! Thank you for your interest
          </div>
        )}
        {subscriptionStatus === "failed" && !errorMessage && (
          <div className="newsletterUnderText">Failed to subscribe</div>
        )}
        {subscriptionStatus === "failed" && errorMessage && (
          <div className="newsletterUnderText">{errorMessage}</div>
        )}
        {loading && (
          <div className="loaderContainer">
            <div className="loader"></div>
          </div>
        )}

        {!subscriptionStatus && !loading && (
          <div className="newsletterUnderText">
            Be the first one to know by signing up to the newsletter
          </div>
        )}
      </form>
    </>
  );
}

export default NewsletterSubscribe;

const getCustomErrorMessage = (mailChimpError) => {
  const error = mailchimpErrors.find((error) => error.title === mailChimpError);
  return error.errorMessage;
};
