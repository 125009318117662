import { useEffect, useState } from "react";
import cursor from "../PW-typer2.png";
import "./ComingSoon.css";
import NewsletterSubscribe from "./NewsletterSubscribe";

const ComingSoon = () => {
  const fullText = "Coming Soon";
  const [text, setText] = useState("");

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setText((prev) => {
        const nextChar = fullText[prev.length];
        // If there's no next character, clear the interval
        if (!nextChar) {
          clearInterval(typingInterval);
          return prev;
        }
        return prev + nextChar;
      });
    }, 250); // speed of typing, in milliseconds

    return () => clearInterval(typingInterval);
  }, []);

  return (
    <div className="background">
      <div className="top-overlay"></div>
      <div className="bottom-circle"></div>

      <div className="center-ellipsis">
        <svg
          width="1613"
          height="1080"
          viewBox="0 0 1613 1080"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="806.5"
            cy="539.5"
            r="805.5"
            stroke="url(#paint0_linear_1_35)"
            stroke-linecap="round"
            stroke-dasharray="12 12"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1_35"
              x1="806.5"
              y1="-266"
              x2="806.5"
              y2="1345"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-opacity="0" />
              <stop offset="0.5" stop-color="#CD79FD" />
              <stop offset="1" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="inner-content">
        <img
          src="https://cdn.publiwrite.com/static/common/images/logo-web-dark.svg"
          alt="Publiwrite Logo"
          className="logo"
        />

        <div>
          <div className="comingSoonText">
            {`${text}`}
            <img src={cursor} className="PW-type" />
          </div>
        </div>

        <NewsletterSubscribe />
      </div>

      <div className="top-circle"></div>
      <div className="bottom-overlay"></div>
    </div>
  );
};

export default ComingSoon;
